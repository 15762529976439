
import { defineComponent, onMounted, ref, watch } from "vue";
import $ from "jquery";
export default defineComponent({
  name: "ProCateList",
  props: {
    list: {
      type: Array,
      default: () => {
        return [];
      },
    },
    children: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  emits: ["handleclick", "handlesecclick"],
  setup(props, ctx) {
    const height = ref("0px");
    onMounted(() => {
      height.value =
        Number($(window).height()) -
        Number($("header").height()) -
        Number($(".home-wapper .title").height()) -
        Number($("footer").height()) +
        "px";
    });
    const active = ref(0);
    watch(
      () => {
        return props.list;
      },
      (list) => {
        active.value = (list as any) && (list as any)[0].categoryCode;
      }
    );

    function onClick(id: string) {
      active.value = Number(id);
      ctx.emit("handleclick", id);
    }
    function onSecond(classid: string) {
      let type = 2;
      if (!classid) {
        classid = String(active.value);
        type = 0;
      }

      ctx.emit("handlesecclick", classid, type);
    }

    return {
      height,
      onClick,
      onSecond,
      active,
    };
  },
});
